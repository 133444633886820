import React from 'react' 
const TeamsText = () =>{ return (
    <div>
        <h1>Lagen</h1>
        <h2>Djurgårdens lag säsongen 2023/2024</h2>
        <h3>Damer:</h3>
        <p>A-laget spelar i div 2.</p>
        <p>B-laget spelar i div 3.</p>
        <p>C-laget spelar i div 3.</p>
        <p>D-laget spelar i div 3.</p>
        <h3>Herrar:</h3>
        <p>A-laget spelar i div 5. </p>
        <p>B-laget spelar i div 6.</p>
        <p>C-laget spelar i div 6.</p>
        <p>D-laget spelar i div 7.</p>
        <h3>
            Veteraner:
        </h3>
        <p>A-laget spelar i div 3.</p>
        <p>B-laget spelar i div 4.</p>
        <p>För kontakt med respektive lagledare mejla till <a href = "mailto: difbtx@gmail.com">difbtx@gmail.com</a></p>
   </div>
    ); 
};

export default TeamsText